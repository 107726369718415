// Import Roboto font from Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// Bootstrap
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here

// Modify default themes
$primary: #000;

$enable-dark-mode: false;
$enable-gradients: true;

$gradient-1: #414eed;
$gradient-2: #c14eb6;
$gradient-3: #ff5221;
$primary-gradient: linear-gradient(
  90deg,
  $gradient-1 0%,
  $gradient-2 50%,
  $gradient-3 100%
);

$body-color: rgba(0, 0, 0, 0.83);
$body-bg: #f5f5f5;

$font-family-sans-serif:
  Roboto,
  system-ui,
  -apple-system,
  "Segoe UI",
  "Helvetica Neue",
  "Noto Sans",
  "Liberation Sans",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji" !default;

$headings-font-weight: 300;

$btn-border-width: 0;
$btn-border-radius-lg: 50rem;

$list-group-bg: #fff;

$offcanvas-bg-color: #fff;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/offcanvas";

@import "bootstrap/scss/helpers";

// Enable responsive utilities
$utilities: map-merge(
  $utilities,
  (
    "position":
      map-merge(
        map-get($utilities, "position"),
        (
          responsive: true,
        )
      ),
    "top":
      map-merge(
        map-get($utilities, "top"),
        (
          responsive: true,
        )
      ),
    "bottom":
      map-merge(
        map-get($utilities, "bottom"),
        (
          responsive: true,
        )
      ),
    "translate-middle":
      map-merge(
        map-get($utilities, "translate-middle"),
        (
          responsive: true,
        )
      ),
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          responsive: true,
        )
      ),
    "min-viewport-height":
      map-merge(
        map-get($utilities, "min-viewport-height"),
        (
          responsive: true,
        )
      ),
    "rounded":
      map-merge(
        map-get($utilities, "rounded"),
        (
          responsive: true,
        )
      ),
  )
);

// Add values

$utilities: map-merge(
  $utilities,
  (
    "min-viewport-height":
      map-merge(
        map-get($utilities, "min-viewport-height"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "min-viewport-height"), "values"),
              (
                auto: auto,
                75: 75vh,
              )
            ),
        )
      ),
    "min-viewport-width":
      map-merge(
        map-get($utilities, "min-viewport-width"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "min-viewport-width"), "values"),
              (
                auto: auto,
                75: 75vw,
              )
            ),
        )
      ),
  )
);

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// 8. Add additional custom code here

// Define text gradient mixin
@mixin text-gradient($primary-gradient) {
  background-image: $primary-gradient;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}
@mixin background-gradient($primary-gradient) {
  background-image: $primary-gradient;
}

.text-gradient {
  @include text-gradient($primary-gradient);
}

/* Overwrite reboot */

strong {
  color: $body-emphasis-color;
}

/* Overwrite components */

// Button
.btn-primary {
  @include background-gradient($primary-gradient);
}
// Nav
.nav-link:hover {
  @include text-gradient($primary-gradient);
}
// Offcanvas
.offcanvas {
  height: auto !important;
  max-height: 90vh; // Optional: set a maximum height
}

/* Custom styles */

// Layouts for logotype
.logo {
  .icon {
    vertical-align: middle;
    // Shift to make it look visually centered
    position: relative;
    bottom: 0.1em; // An estimate
    @include border-radius(20%);
  }
}
// General icons
.icon {
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
}

/* Custom layouts */

#hero {
  line-height: 1.2;
  height: auto;

  > span {
    display: inline-block;
    height: 1.2em;
    vertical-align: top;
  }

  .keyword {
    overflow: hidden;
    position: relative;
    width: 8em; // Adjust for actual width of text

    $keywords: 6; // Number of keywords
    span {
      display: inline-block;
      animation: scrollText calc($keywords * 2s) infinite;
      opacity: 0;
      position: absolute;
      white-space: nowrap;

      @for $i from 1 through $keywords {
        &:nth-child(#{$i}) {
          --i: #{$i - 1};
          animation-delay: calc(2s * var(--i));
        }
      }
    }
  }
}

@keyframes scrollText {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  2.5% {
    opacity: 1;
    transform: translateY(0);
  }

  12.5% {
    transform: translateY(0);
    opacity: 1;
  }

  15% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

// Document typography defaults
article {
  line-height: 1.7;
  max-width: 60em;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }

  // List styling
  ul,
  ol {
    li {
      margin-bottom: 0.5rem;
    }
  }

  // Blockquote styling
  blockquote {
    margin: 2rem 0;
    padding: 1rem 2rem;
    border-left: 4px solid $primary;
    background-color: rgba($primary, 0.05);
    font-style: italic;
  }

  // Code blocks
  pre {
    padding: 1rem;
    margin: 1.5rem 0;
    background-color: rgba($primary, 0.05);
    border-radius: 4px;
    overflow-x: auto;
  }

  // Links
  a {
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;

    &:hover {
      color: darken($primary, 15%);
    }
  }
}
